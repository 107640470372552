import React from 'react';
import styled from 'styled-components';

const StyledText = styled.div`
	text-align: left;
	
	margin: 1em 0;
	h2 {
		margin-bottom: 0.8em;
	}
`;

function AlertText(props) {
	const alertData = props.alertText;
  const elementAry = alertData ? alertData.elements : [];
  const alertText = elementAry.map((text, i) => 
  	text.isOn === 'ON' ?
  	<div key={i} style={{'backgroundColor':'#FFEECB','padding':'1em'}}> 
	  	<h2 className="h4">{text.Title}</h2>
	  	<p>{text.Content}</p>
			<p><a target="_blank" rel="noopener noreferrer" href={text.Link}>Click here to learn more.</a></p>
		</div>
		: <div key={i}></div>
  );

	return (
		<StyledText>
			{alertText}
		</StyledText>
	)
};

export default AlertText;