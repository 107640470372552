import React from 'react';
import styled from 'styled-components';

const StyledHero = styled.div`
  max-width: 1200px;
  .hero-title {
    text-transform: uppercase;
  }
  /* Home hero */
  &.home {
  	display: grid;
  	grid-template-areas: "." "hero-img";
  	grid-template-columns: 25% auto;
  	.hero-img {
  		grid-area: 1 / col4-start / last-line / 6;
			position: relative;
			max-width: 800px;
      min-height: 472px;
      background-color: #b3b3b3;
		}
		.hero-title {
	  	position: absolute;
		  top: 30%;
		  left: 10%;
		  max-width: 500px;
		}
	}

  /* About hero */
  &.about {
  	display: block;
    .hero-title-wrapper {
      display: none;
    }
		.hero-img {
      background-color: #adaaa3;
      left: 0;
      margin: auto;
      max-width: 1200px;
      max-height: 500px;
      position: absolute;
      object-fit: cover;
      right: 0;
      width: 100%;
		}
  }
  /* Services hero */
  &.services {
  	display: grid;
  	grid-gap: 15px;
  	grid-template-columns: 100%;
	  grid-template-rows: auto;
	  grid-template-areas: 
	    "header"
	    "main";
  	.hero-img {
  		grid-area: main;
  		height: 200px;
  		object-fit: cover;
  		width: 100%;
      background-color: #718c68;
  	}
  	.hero-title {
  		grid-area: header;
  		text-align: center;
  	}
  }

  /* Desktop all pages hero */
  .hero-title {
	  font-size: 64px;
	  font-weight: bold;
	  padding: 0 0.4em;
	  text-align: left;
  }

  /* Mobile all pages hero */
  @media (max-width: 680px) {
  	display: block !important;
  	position: relative;
  	
  	img.hero-img { 
  		object-fit: cover; 
  		width: 100% !important;
  		height: 200px;
  	}
  	.hero-title-wrapper {
  		margin: 0 10px;
    	padding: 0 0.4em;
  		position: absolute; 
  	}
  	h1.hero-title { 
  		span {
  			background-color: white;
  		}
  		display: inline-block;
  		font-size: 32px;
  		position: static;
  		left: 0 !important;
  	}
  	&.home {
      .hero-img {
        min-height: 175px;
      }
  		.hero-title-wrapper {
  			top: 40px;
  		}
  	}
  	&.services {
  		.hero-title-wrapper {
  			top: 80px;
        left: 0;
        right: 0;
        margin: auto
  		}
  	}
    &.about {
      .hero-title-wrapper {
        display: block;
        text-align: center;
        top: 80px;
        left: 0;
        right: 0;
        margin: auto
      }
    }
  }
`;

function Hero(props) {
	return (
		<StyledHero className={props.page}>
			<img className="hero-img" src={require('../images/' + props.imgSrc)} alt={props.title}/>
			<div className="hero-title-wrapper">
				<h1 className="hero-title"><span>{props.title}</span></h1>
			</div>
		</StyledHero>
	)
};

export default Hero;