import React, { Component } from 'react';
import styled from 'styled-components';
import right from '../images/right.svg';
import Loader from './Loader';

const StyledContactForm = styled.div`
  grid-area: form;
  label {
  	font-family: "Source Sans Pro";
  	font-size: 1em;
  }
  .red {
    color: red;
  }
  .due-date-label {
    display: flex;
    justify-content: space-between;
    .availability {
      font-weight: bold;
    }
  }
  input {
  	display: block;
    background-color: #f2f2f2;
    font-size: 1.25em;
    margin: 5px 0 15px;
    padding: 5px;
    width: 100%;
  }
  textarea {
  	background-color: #f2f2f2;
  	font-size: 1.25em;
  	height: 150px;
  	outline: none;
  	overflow: auto;
  	resize: none;
  	width: 100%;
  }
  @media (max-width: 600px) {
    display: block;
  }
`;

const StyledButton = styled.button`
	border: 1px solid black;
	margin-top: 1em;
	padding: 5px 10px;
	.arrow {
		background-image: url(${right});
		background-repeat: no-repeat;
		margin-left: 10px;
		padding: 0 8px;
    position: relative;
    top: 8px;
	}
  span {
    text-transform: uppercase;
  }
	&:hover {
		background-color: #FFCD05;
	}
  &.display-none {
    display: none;
  }
`;

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      name: '',
      email: '',
      phone: '',
      dueDate: '',
      message: '',
      loading: false,
      messageSent: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  
  handleSubmit = event => {
    // headers: { 'Content-Type': 'application/json' },
    // alert("You are submitting " + this.state);
    this.isLoading();
    const scriptURL = process.env.REACT_APP_GS_POST;
    event.preventDefault()
    const requestOptions = {
        method: 'POST',
        body: new FormData(this.form)
    };
    fetch(scriptURL, requestOptions)
      .then(async response => {
          const data = await response.json();
          // check for error response
          if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }
          this.setState({
            messageSent: true,
            loading: false,
          });
      })
      .catch(error => {
          this.setState({ errorMessage: error });
          console.error('There was an error!', error);
      });

  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  isLoading() {
    this.setState({
      loading: true,
    })
  }

  render() {
    const submitMessage = this.state.messageSent ? 
      <div style={{'marginTop': '1em'}}>
        <h4>Your message has been sent!</h4>
        <p>We will review your message and get back to you shortly.</p>
      </div>
      : '';
    const showLoading = this.state.loading ? <Loader /> : '';
    const buttonVisibility = this.state.loading || this.state.messageSent ? 'display-none' : '';

    const contactData = this.props.contactData;
    const elementAry = contactData ? contactData.elements : [];
    const availability = elementAry.map((row, i) => 
      row.Type === 'availability' ? <span className="availability" key={i}>{row.Content}</span> : <span key={i}></span>
    )
  	return (
      <StyledContactForm>
        <div className="form">
          <form onSubmit={this.handleSubmit} ref={el => (this.form = el)}>
            <label>
              Name <span className="red">*</span>
              <input
                type="text"
                name="name"
                onChange={this.handleChange}
                value={this.state.name}
                required
              />
            </label>

            <label>
              Email <span className="red">*</span>
              <input
                type="email"
                name="email"
                onChange={this.handleChange}
                value={this.state.email}
                required
              />
            </label>

            <label>
              Phone
              <input
                type="tel"
                name="phone"
                onChange={this.handleChange}
                value={this.state.phone}
              />
            </label>

            <label>
              <span className="due-date-label">
                <span>Due Date <span className="red">*</span></span>
                {availability}
              </span>
              <input
                type="date"
                name="dueDate"
                onChange={this.handleChange}
                value={this.state.dueDate}
                required
              />
              
            </label>

            <label>
              Message
              <textarea name="message" value={this.state.message} onChange={this.handleChange} />
            </label>
            <div style={{'display':'flex'}}>
              <StyledButton className={{buttonVisibility}}>
              	<span className="h3">Submit<span className="arrow"></span></span>
              </StyledButton>
              {showLoading}
            </div>
            {submitMessage}
          </form>
        </div>
      </StyledContactForm>
		)
  }
}

export default Form;
