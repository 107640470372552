import React from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import LogoFull from '../logo-full.svg';

const StyledNavbar = styled.div`
	display: grid;
	grid-template-areas: "logo-wrapper" "link-wrapper";
	grid-template-columns: 50% 50%;
	font-family: 'Source Sans Pro', sans-serif;
	max-width: 1400px;
  margin: 0 auto;
	padding: 1em;
	text-transform: uppercase;

	.link-wrapper { justify-self: end; padding-bottom: 6px; }
	.logo-wrapper { justify-self: start; }

	a { 
		padding: 0 10px;
	}
	img { width: auto; height: 30px; }

	@media (max-width: 600px) {
		grid-template-columns: 100%;
		.link-wrapper { justify-self: stretch; }
	}
`;

const Navbar = () => (
		<StyledNavbar>
			<div className="logo-wrapper">
				<Link to="/">
					<img src={LogoFull} alt="site logo of a geometric flower"/>
				</Link>
			</div>
			<div className="link-wrapper">
				<Link to="/">Home</Link>
				<Link to="/about">About</Link>
				<Link to="/services">Services</Link>
				<Link to="/contact">Contact</Link>
			</div>
		</StyledNavbar>
);

export default Navbar;