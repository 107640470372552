
// .wrapper {
//   display: grid;
//   grid-template-columns: 100px 100px 100px;
//   grid-gap: 10px;
//   background-color: #fff;
//   color: #444;
// }
//<h1>Hello, {props.name}</h1>

import React from 'react';
import styled from 'styled-components';

const StyledGridContainer = styled.div`
	/* display: grid; */
	/* grid-template-columns: repeat(12, 20px [col-start]); */
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
`;

const GridContainer = ({children}) => (
  <StyledGridContainer>{children}</StyledGridContainer>
)

export default GridContainer;