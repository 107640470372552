import React from 'react';
import Hero from '../Components/Hero';
import styled from 'styled-components';

const StyledDisclaimer = styled.div`
	margin-top: 1em;
	border-top: 1px solid #000;
	h2 {
		margin: 1em 0;
	}
	text-align: left;
	ul {
		/* list-style-type: circle; */
		list-style-type: none;
		margin-left: 1em;
		margin-bottom: 1em;
		li {
			margin-bottom: 1em;
		}
		li:before {
			content: '\\25E6';
    	position: relative;
    	left: -15px;
		}
	}
`;

const StyledInfoBar = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px;
	text-align: left;
	margin: 1em 0;
	p {
		margin: 1em 0;
	}
	@media (max-width: 680px) {
		display: block;
		padding: 0 10px;
		.col {
			padding-bottom: 2em;
		}
	}
`;

const NarrowSection = styled.div`
	margin: 2em 0 1em;
`;

function Services(props) {
	const servicesData = props.servicesData
	const elementAry = servicesData ? servicesData.elements : [];
	const servicesContent = elementAry.map((row, key) => 
		row.Type === '3-col' ? 
			<div className="col col3" key={key}>
				<h2 className="h4">{row.Title}</h2>
				<p className="prewrap">{row.Content}</p>
			</div>
		: ''
	);
	return (
		<div className="Services">
			<Hero title="Services" imgSrc="services-01.jpg" page="services" />
			<NarrowSection >
				<StyledInfoBar>
					{servicesContent}
				</StyledInfoBar>
			</NarrowSection>
			<StyledDisclaimer className="disclaimer">
				<h2 className="h4">Disclaimer</h2>
				<p>As a DONA (Doulas of North America) trained and practicing doula, Susan is trained to provide support for women and their partners through the prenatal, birth and postpartum experience. Doulas are <u>not</u> medical professionals. As such, the following services are not provided by Susan:</p>
				<ul>
					<li>Provide, diagnose, interpret, treat, administer or perform anything clinical or medical.  In this regard, Susan does not attend intentionally unassisted births.</li>
					<li>Make decisions or speak for the birthing individual or stand in the way of other members of the individual team.</li>
					<li>Promote her ideas and intentions for the birth over those of the birthing individual.</li>
					<li>Guarantee a specific outcome.  Susan can not promise everything will go the way the birthing individual planned.</li>
				</ul>
				<p>For more information see DONA's <a href="https://www.dona.org/what-is-a-doula/scope-and-ethics/" rel="noopener noreferrer">Standards of Practice</a>.</p>
			</StyledDisclaimer>
		</div>
	)
}

export default Services;


// function InfoBar(props) {
// 	const columns = elementAry.map((column, i) => 
// 		column.Type === 'whyModule' ? 
// 			<div className="col col3" key={i}>
// 				<h2 className="h4">{column.Title}</h2>
// 				<p className="prewrap">{column.Content}</p>
// 				<p className="caption"><a href={column.CitationLinkt} target="_blank" rel="noopener noreferrer">{column.CitationTitle}</a></p>
// 			</div>
// 		: '' 
// 	);
// 	return (
// 		<StyledInfoBar>
// 			{columns}
// 		</StyledInfoBar>
// 	)
// }

// InfoBar.defaultProps = {
// 	infoBarData: {
// 		elements: [
// 			{
// 				Title: "What is a Doula?",
// 				Content: "A birth doula is a trained companion who supports a birthing person during labor and birth. Doulas provide continuous, one-on-one care, as well as information, advocacy, physical support, and emotional support to birthing persons and their partners to help achieve healthy and satisfying birth experiences.",
// 				CitationTitle: "1. DONA International. What is a Doula?",
// 				CitationLink: "https://www.dona.org/what-is-a-doula/",
// 				Type: "whyModule",
// 			},
// 			{
// 				Title: "Why a Doula?",
// 				Content: "Overall, women who receive continuous support during labor are more likely to experience positive birth outcomes and shortened labor. Studies have shown that women with supported birth are more likely to have spontaneous vaginal births and less likely to have any pain medication, epidurals, or negative feelings about childbirth, vacuum or forceps-assisted births, and Cesareans.",
// 				CitationTitle: "2. Bohren, et al. Continuous Support for Women During Childbirth. The Cochrane Database of Systematic Reviews. 2017 July.",
// 				CitationLink: "https://www.ncbi.nlm.nih.gov/pubmed/28681500",
// 				Type: "whyModule",
// 			},
// 			{
// 				Title: "Why Susan?",
// 				Content: "As a DONA certified birth doula, Susan accompanies women in labor to help ensure a safe and satisfying birth experience. Drawing on her professional training, knowledge and experience to provide emotional support, physical comfort, and as needed, communication with the staff, Susan works to make sure that you have the information you need to make informed decisions as they arise in labor.",
// 				CitationTitle: "",
// 				CitationLink: "",
// 				Type: "whyModule",
// 			},
			
// 		]
// 	}
// }
