import React from 'react';
import Hero from '../Components/Hero';
import Section from '../Layout/Section';
import TestimonialCarousel from '../Components/Testimonials';
import InfoBar from '../Components/InfoBar';

function Home(props) {
	return (
		<div className="Home" style={{'overflow':'hidden'}}>
			<Hero title="Birth Doula Services" imgSrc="hero-01.jpg" page="home"/>
			<Section><TestimonialCarousel testimonialData={props.testimonialsData}/></Section>
			<Section><InfoBar infoBarData={props.homeData}/></Section>
		</div>
	)
}

export default Home;