import React from 'react';
import styled from 'styled-components';

const StyledInfoBar = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px;
	text-align: left;
	margin: 1em 0;
	p {
		margin: 1em 0;
	}
	@media (max-width: 600px) {
		display: block;
		padding: 0 10px;
		.col {
			padding-bottom: 2em;
		}
	}
`;

function InfoBar(props) {
	const infoBarData = props.infoBarData;
	const elementAry = infoBarData ? infoBarData.elements : [];
	const columns = elementAry.map((column, i) => 
		column.Type === 'whyModule' ? 
			<div className="col col3" key={i}>
				<h2 className="h4">{column.Title}</h2>
				<p className="prewrap">{column.Content}</p>
				<p className="caption"><a href={column.CitationLinkt} target="_blank" rel="noopener noreferrer">{column.CitationTitle}</a></p>
			</div>
		: '' 
	);
	return (
		<StyledInfoBar>
			{columns}
		</StyledInfoBar>
	)
}

InfoBar.defaultProps = {
	infoBarData: {
		elements: [
			{
				Title: "What is a Doula?",
				Content: "A birth doula is a trained companion who supports a birthing person during labor and birth. Doulas provide continuous, one-on-one care, as well as information, advocacy, physical support, and emotional support to birthing persons and their partners to help achieve healthy and satisfying birth experiences.",
				CitationTitle: "1. DONA International. What is a Doula?",
				CitationLink: "https://www.dona.org/what-is-a-doula/",
				Type: "whyModule",
			},
			{
				Title: "Why a Doula?",
				Content: "Overall, women who receive continuous support during labor are more likely to experience positive birth outcomes and shortened labor. Studies have shown that women with supported birth are more likely to have spontaneous vaginal births and less likely to have any pain medication, epidurals, or negative feelings about childbirth, vacuum or forceps-assisted births, and Cesareans.",
				CitationTitle: "2. Bohren, et al. Continuous Support for Women During Childbirth. The Cochrane Database of Systematic Reviews. 2017 July.",
				CitationLink: "https://www.ncbi.nlm.nih.gov/pubmed/28681500",
				Type: "whyModule",
			},
			{
				Title: "Why Susan?",
				Content: "As a DONA (Doulas of North America) trained and practicing birth doula, Susan accompanies women in labor to help ensure a safe and satisfying birth experience. Drawing on her professional training, knowledge and experience to provide emotional support, physical comfort, and as needed, communication with the staff, Susan works to make sure that you have the information you need to make informed decisions as they arise in labor.",
				CitationTitle: "",
				CitationLink: "",
				Type: "whyModule",
			},
			
		]
	}
}

export default InfoBar;