import React from 'react';
import Hero from '../Components/Hero';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import right from '../images/right.svg';
import rightCornerBottom from '../images/right-corner.svg';
import rightCornerTop from '../images/right-corner-top.svg';

const StyledAbout = styled.div`
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-template-areas:
	". body";
  text-align: left;
	.about-text {
    background-color: white;
    border
    grid-area: body;
    margin: 1em;
    /* position: relative; */
    /* top: 350px; */
    margin-top: 350px;
    z-index: 1;
    .about-text-padding {
      box-shadow: 0 1px 3px rgba(0,0,0,0.05), 0 1px 2px rgba(0,0,0,0.1);
      transition: all 0.3s cubic-bezier(.25,.8,.25,1);
      position: relative;
    	padding: 4em;
    	h1 {
    		margin-bottom: 15px;
        text-transform: uppercase;
    	}
    }
    .border {
      background-repeat: no-repeat;
      padding: 1.2em;
      position: absolute;
    }
    .border-top {
    	background-image: url(${rightCornerTop});
    	top: 0;
    	right: 0;
    }
    .border-right {
      background-image: url(${rightCornerBottom});
      bottom: 0;
      right: 0;
    }
    .contact-link {
      margin-top: 2em;
    	span {
    		background-image: url(${right});
    		background-repeat: no-repeat;
    		padding: 0 8px;
		    position: relative;
		    top: 8px;
		    left: 20px;
    	}
    }
	}
  @media (max-width: 680px) {
    display: block;
    .about {
      .hero-img {
        position: static;
      }
    }
    .about-text {
      margin: 1em 0 0;
      .about-text-padding {
        box-shadow: none;
        position: static;
        padding: 0;
        h1 {
          font-size: 32px;
        }
      }
      .hero-title-desktop {
        display: none;
      }
      h2 {
        font-size: 28px;
        span {
          padding: 0 6px;
        }
      }
    }

  }
`;

function About(props) {
  const aboutData = props.aboutData;
  const elementAry = aboutData ? aboutData.elements : [];
  const about = elementAry.map((content, i) => 
    content.Type === 'pageTitle' ? <h1 className="hero-title-desktop" key={i}>{content.Title}</h1> : <p key={i} className="prewrap">{content.Content}</p>
  );
	return (
		<StyledAbout className="About">
			<Hero title="About Susan" imgSrc="susan-01.jpg" page="about" className="hero" />
			<div className="about-text">
        <div className="about-text-padding">
				  {about}
        </div>
				<div className="contact-link">
					<h2 className="h3"><Link to="/contact">Contact</Link><span></span></h2>
				</div>
			</div>
		</StyledAbout>
	)
}

About.defaultProps = {
  aboutData: {
    elements: [
      {
        Title: "About Susan",
        Content: "",
        Type: "pageTitle"
      },
      {
        Title: "",
        Content: "Susan is a DONA (Doulas of North America) trained and practicing doula in the San Francisco East Bay Area. She has attended hospital, home, and cesarian births, and is committed to supporting clients through their specific birth plans and circumstances.",
        Type: "paragraph"
      },
      {
        Title: "About Susan",
        Content: "Susan has a PhD in Plant Biology from U.C. Berkeley and teaches Biology at Diablo Valley College and UC Berkeley Extension. You can find Susan out and about the East Bay, swimming at local pools, hiking in Tilden, shopping at Monterey Market, or competing with her kids at the annual Albany triathalon.",
        Type: "paragraph"
      },
    ]
  }
}

export default About;
