import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Tabletop from 'tabletop';
import { Global } from "./styles/global";
import Navbar from './Components/Navbar';
import GridContainer from './Layout/GridContainer';
import Home from './Layout/Home';
import About from './Layout/About';
import Services from './Layout/Services';
import Contact from './Layout/Contact';
import Footer from './Components/Footer';

import './App.css';

class App extends Component {
  constructor() {
    super()
    this.state = {
      data: []
    }
  }
  componentDidMount() {
    Tabletop.init({
      key: process.env.REACT_APP_GS_KEY,
      callback: googleData => {
        this.setState({
          data: googleData
        })
      },
      // simpleSheet: true
    })
  }

  render() {
    return (

      <div className="App">
        <Global />
        <Navbar />
        <GridContainer>
          <Route exact path="/" render={() => <Home homeData={this.state.data.Home} testimonialsData={this.state.data.Testimonials} />} />
          <Route path="/about" render={() => <About aboutData={this.state.data.About} />} />
          <Route path="/services" render={() => <Services servicesData={this.state.data.Services} />} />
          <Route path="/contact" render={() => <Contact contactData={this.state.data.Contact} alertData={this.state.data.Alert} />} />
        </GridContainer>
        <Footer />
      </div>

    );
  }
}

export default App;
