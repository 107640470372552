import { createGlobalStyle } from "styled-components";

export const Global =  createGlobalStyle`
  
  * { 
    margin: 0;
    padding: 0;
    outline: 0; 
    border: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: 0;
  }
  html, body, #root {
    height: 100%;
  }
  body {
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
  }
  h1, .h1 , h2, .h2, h3, .h3, h4, .h4 {
    font: 16px 'Source Sans Pro', sans-serif;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 2px;
  }
  body, p, .p, input, caption {
    font: 16px 'Merriweather', serif;
  }
  h1, .h1 {
  	font-size: 60px;
  }
  h2, .h2 {
  	font-size: 48px;
  }
  h3, .h3 {
  	font-size: 36px;
  }
  h4, .h4 {
  	font-size: 24px;
  }
  .caption {
  	font-size: 10px;
  }
  p {
  	margin-bottom: 1em;
  }
  p.prewrap {
     white-space: pre-wrap;
  }
  .superscript {
  	font-size: 10px;
  	margin-bottom: 5px;
  }
  a {
  	color: black;
    text-decoration: underline;
    text-decoration-color: #FFCD05;
    text-decoration-thickness: 2px;
  }
  a:visited {
  	color: black;
  }
  a:hover {
  	text-decoration: underline;
  	text-decoration-color: black;
  }
  ul {
    list-style: none;
  }
  button {
    cursor: pointer;
  }
`;