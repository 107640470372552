import React from 'react';
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';
import styled from 'styled-components';
import leftImg from '../images/left.svg';
import rightImg from '../images/right.svg';

const StyledCarousel = styled(CarouselProvider)`
	&.carousel {
		position: relative;
	}
	button {
		position: absolute;

	}
	.button-back {
		background: url(${leftImg});
		background-repeat: no-repeat;
		left: 10%;
		padding: 10px;
		top: 50px;
	}
	.button-next {
		background: url(${rightImg});
		background-repeat: no-repeat;
		padding: 10px;
		right: 10%;
		top: 50px;
	}
	.slider-animation {
		transition: transform 500ms;
	  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000); /* easeInOutCubic */
	  will-change: transform;
	}
	.slider {
		.slide {
			h2 {
				margin-bottom: 10px;
			}
			p {
				max-width: 650px;
				margin: 0 auto 10px auto;
			}
		}
	}

	@media (max-width: 600px) {
		.button-back {
			left: 10px;
			top: 100px;
		}
		.button-next {
			right: 10px;
			top: 100px;
		}
	}
`;

function TestimonialCarousel(props) {
		const testimonialData = props.testimonialData;
		const elementAry = testimonialData ? testimonialData.elements : [];
		const slides = elementAry.map((testimonial, i) => 
			<Slide className="slide" key={i} index={i}>
				<h2 className="h4"><span></span>"{testimonial.TestimonialExcerpt}"<span></span></h2>
				<p>{testimonial.TestimonialBody}</p>
				<p>- {testimonial.Author} -</p>
			</Slide>
		)
    return (
      <StyledCarousel
        totalSlides={2}
        isPlaying={true}
        interval={10000}
        infinite={true} 
        isIntrinsicHeight={true}
        naturalSlideWidth={100}
        naturalSlideHeight={125}
      >
        <Slider className="slider" classNameAnimation="slider-animation">
          {slides}
        </Slider>
        {/*<ButtonBack className="button-back"></ButtonBack>
        <ButtonNext className="button-next"></ButtonNext>*/}
        <DotGroup />
      </StyledCarousel>
    );
}

TestimonialCarousel.defaultProps = {
	testimonialData: {
		elements: [
			{
				Author: "Hannah",
				TestimonialExcerpt: "Susan was incredible!",
				TestimonialBody: "Susan made my birth what it was, and it was a beautiful experience. You could not be in better hands. My midwife that has practiced for 41 years said she has never seen such an incredible doula.",
			},
			{
				Author: "Taylor",
				TestimonialExcerpt: "Having Susan there made all the difference.",
				TestimonialBody: "There are a lot of things about delivery that you can't really plan for, but having Susan as part of our plan was truly the best decision. Susan gave me and my partner the emotional and physical support we needed to stay calm and confident through such a crazy intense experience. For that and for so many other things, Susan will always be a part of our family.",	
			}
		]
	}
}

export default TestimonialCarousel;