import React, { Component } from 'react';
import styled from 'styled-components';
import Form from '../Components/Form';
import map from '../images/map-01.jpg';
import AlertText from '../Components/AlertText';

const StyledTitle = styled.h1`
	font-size: 64px;
  font-weight: bold;
  /* padding: 0 0.4em; */
  text-align: left;
  text-transform: uppercase; 
  @media (max-width: 600px) {
  	font-size: 32px;
  }
`;

const StyledBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 30px;
  grid-template-areas: 
    "body ."
    "form formImg";
  text-align: left;
  .alert {
    grid-area: alert;
  }
  .subtitle {
    grid-area: body;
  }
  .form {
    grid-area: form;
  }
  .formImg-container {
    grid-area: formImg;
    p {
      font-size: 0.8em;
      margin-top: 1em;
    }
  }
  .formImg {
    background-image: url(${map});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    grid-area: formImg;
    height: 430px;
    width: auto;
  }
  @media (max-width: 600px) {
    display: block;
    .formImg-container {
      margin-top: 1em;
    }
  }
`;

class Contact extends Component {
  render() {

  	return (
  		<div className="Contact">
  			<StyledTitle>Contact</StyledTitle>
        <AlertText className="alert" alertText={this.props.alertData} />
        <StyledBody>
          
          <p className="subtitle">For information about services, availability, and pricing, fill out the form below to get in touch.</p>
          <Form contactData={this.props.contactData}/>
          <div className="formImg-container">
            <div className="formImg"></div>
            <p>Susan serves hospitals, birth centers and homes in this geographic region.  If you are planning on delivering outside of this area, please include that in your contact request for consideration.</p>
          </div>
        </StyledBody>

  		</div>
	)
  }
}

export default Contact;
