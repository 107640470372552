import React from 'react';
import styled from 'styled-components';

const StyledSection = styled.div`
	margin: 8em 0 2em;
	@media (max-width: 680px) {
		margin: 2em 0 1em;
	}
`;

const Section = ({children}) => (
  <StyledSection>{children}</StyledSection>
);

export default Section;