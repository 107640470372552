import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledFooter = styled.div`
	margin: 5em auto 1em;
	padding: 10px;
	text-align: center;
	p {
		font: 0.8em 'Source Sans Pro', sans-serif;
	}
`;

const Footer = () => (
	<StyledFooter>
		<p>For more information on rates and availability or to schedule a consultation, please <Link to="/contact">contact me here</Link>.</p>
	</StyledFooter>
)

export default Footer;